import * as React from "react"
import { useEffect } from "react"
import { useMutation, gql } from "@apollo/client"
import Layout from "../components/Layout"
import { navigate } from "gatsby"
import Section from "gatsby-theme-wild-child/src/components/Section"
import { Heading } from "@chakra-ui/react"

const LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`

const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      capabilities
    }
  }
`

export default function LogOut() {
  const [logOut, { called, loading, error, data }] = useMutation(LOG_OUT, {
    refetchQueries: [{ query: GET_USER }],
  })
  const loggedOut = Boolean(data?.logout?.status)

  useEffect(() => {
    logOut()
    navigate("/login")
  }, [logOut])

  return (
    <Layout>
      <Section height="100vh" bg="dark.full">
        <Heading color="white">
          {loggedOut ? "Logged out" : "Logging out..."}
        </Heading>
      </Section>
    </Layout>
  )
}
